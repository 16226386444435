import banner from '../../assets/images/alohashaka-logo.png';
import logo from '../../assets/images/logo-palm-ol.png';
function About() {
	return (
		<section className="page as-about" id='as_about'>
			<div className="row pas-about-content">
				<div className="box-4 box-off-1">
					<div className="row pas-about-info-holder">
						<div className="row pas-about-info">
							<div className='box-10 box-sm-9 box-center-sm box-md-9 box-center-md'>
								<div className="row page-title">
									Aloha! Shaka~
								</div>
								<div className="row pas-about-description">
									{`
								Aloha Shaka is a media & tech company with a laid back attitude.
								We are focused on web technologies, web development and (web) apps.
								We build our services & projects with passion and make use of the hottest technologies.
								At Aloha Shaka we keep ourselves up-to-date with the latest trends & web + tech designs,
								so we can easily adapt to our client's needs and provide a pleasant UX!
								We are no strangers from building simple websites or complicated web apps,
								we are always up for a challenge!
								`}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="box-3 box-off-1">
					<div className="row">
						<div className="row">
							<div className="row">
								<div className="row pas-about-logo-holder">
									<div className="row box-center pas-about-logo">
										<img className='scalable' src={logo}></img>
									</div>
									<div className="row box-center pas-about-banner">
										<img className='scalable' src={banner}></img>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
import About from '../about/about.component';
import { HashLink as Link } from 'react-router-hash-link';

function Company() {
	const companyInfo = [
		{ label: 'Email', content: (<><Link to={'/#as_contact'}>Contact form</Link> or <a href = "mailto: info@alohashaka.com">info@alohashaka.com</a></>) },
		{ label: 'VAT Number', content: 'NL004859781B7' },
		{ label: 'Chamber of commerce', content: '91022479' },
		{ label: 'Address', content: 'Betje Wolfflaan 20' },
	];
	return (
		<>
			<About />
			<section className='page as-company half'>
				<div className='box-8 box-sm-9 box-md-8 box-center'>
					<div className='row'>
						<div className='row asco-title'>
							Aloha Shaka
						</div>
						{companyInfo.map(({ label, content }) => (
							<div key={label} className='row asco-info'>
								<div className='asco-label'>
									{label}
								</div>
								<div className='asco-content'>
									{content}
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
}
export default Company;
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import GlobalService from '../../utils/global.service';

function Contact() {
	const [submitting, setSubmitting] = useState(false);
	const [contactSuccess, setContactSuccess] = useState(false);
	const [contacted, setContacted] = useState(false);
	const [token, setToken] = useState<null | string>();
	const gRecaptchaKey = '6LepMJwnAAAAAGJysaqaVJ0_EG98rP6EIQN3GtjN';
	const recaptchaRef = React.useRef<ReCAPTCHA>(null);

	function onChange(value: any) {
		setToken(value);
	}

	function resetRecaptcha() {
		recaptchaRef.current?.reset();
		setToken(null);
	}

	function formChange() {
		setContacted(false);
	}


	function submitForm(e: React.ChangeEvent<any>) {
		const globalService = new GlobalService;
		e.preventDefault();
		if (!submitting && token) {
			setSubmitting(true);
			const form = e.target;
			const formData = new FormData(form);
			formData.append('recaptcha_token', token);

			const formEntries: any = JSON.stringify(Object.fromEntries(formData.entries()));

			fetch(globalService.BASE_API_URL + 'contact', {
				method: 'POST',
				body: formEntries,
				headers: {
					'Content-type': 'application/json',
				},
			})
				.then((response) => response.json())
				.then((data: any) => {
					if (data.return) {
						form.reset();
						setContacted(true);
						setContactSuccess(true);
					} else {
						setContacted(true);
						setContactSuccess(false);
					}
				}).finally(() => {
					setSubmitting(false);
					resetRecaptcha();
				}).catch(() => {
					setContacted(true);
					setContactSuccess(false);
				});
		}
	}

	return (
		<section className="page as-contact" id='as_contact'>
			<div className="row">
				<div className="box-sm-9 box-md-9 box-5 box-center">
					<form method="post" onSubmit={submitForm} onChange={formChange}>
						<div className="row asc-inputs">
							<div className="box-5 asc-input-holder">
								<div className="asci-label">Your name</div>
								<input required name="name" type="text" placeholder="John Doe"></input>
							</div>
							<div className="box-5 asc-input-holder">
								<div className="asci-label">Company name (optional)</div>
								<input name="company" type="text" placeholder="Aloha Shaka"></input>
							</div>
						</div>
						<div className="row asc-inputs">
							<div className="box-10 asc-input-holder">
								<div className="asci-label">Your email</div>
								<input required name="email" type="email" placeholder="johndoe@alohashaka.com"></input>
							</div>
						</div>
						<div className="row">
							<div className="asc-input-holder">
								<div className="asci-label">Your message</div>
								<textarea name="message" required placeholder="Enter a message to inform us about your inquiry or request"></textarea>
							</div>
						</div>
						{contacted && (
							<div className={`row asc-contact-status ${contactSuccess ? 'ascc-success' : 'ascc-failed'}`}>
								{contactSuccess ?
									(<div className="row">Thank you for your contact request! We will try to get back to you as soon as possible!</div>) :
									(<div className="row">Oops..! Something went wrong on our side.. Please try again at a later time!</div>)}
							</div>
						)}
						<div className="row right">
							<div className='row asc-recaptcha'>
								<div className='box box-right'>
									<ReCAPTCHA
										ref={recaptchaRef}
										sitekey={gRecaptchaKey}
										onChange={onChange}
										onExpired={resetRecaptcha}
									/>
								</div>
							</div>
							<div className='row'>
								<button type="submit" disabled={submitting || !token} className="btn btn-contact">
									Contact us!
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
}

export default Contact;
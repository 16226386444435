import FooterLink from './footer.link.component';

interface LinkCategories {
	label: string,
	links: { label: string, url: string, external: boolean }[]
}

function FooterLinkCategory({ links, label }: LinkCategories) {
	return (
		<div className="box-2 box-md-3 box-sm-5">
			<div className="row as-footer-links">
				<div className="row as-footer-label">
					{label}
				</div>
				<div className="row">
					{links.map((l) => (<FooterLink key={l.label} label={l.label} url={l.url} external={l.external} />))}
				</div>
			</div>
		</div>
	);
}

export default FooterLinkCategory;
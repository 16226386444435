import { Component } from 'react';
import Speakuni from './speakuni/speakuni.component';
import styles from './projects.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

class Projects extends Component {
	public classNames = cx('page', 'as-projects');

	render() {
		return (
			<section className={this.classNames} id='as_projects'>
				<div className="row page-title">
					<div className='box-auto box-off-2'>Projects</div>
				</div>
				<Speakuni />
			</section>
		);
	}
}

export default Projects;
import { Component } from 'react';
import bannerLogo from '../../assets/images/alohashaka-logo.png';
import logo from '../../assets/images/logo-palm-ol.png';
class Homepage extends Component {

	render() {
		return (
			<section className="page as-homepage" id='as_homepage'>
				<div className='vc-holder'>
					<div className='vc-box'>
						<div className='row center'>
							<div className='box box-auto alohashaka-logo'>
								<img className='scalable' src={logo}></img>
							</div>
						</div>
						<div className='row center'>
							<div className='box box-auto alohashaka-banner'>
								<img className='scalable' src={bannerLogo}></img>
							</div>
						</div>
					</div>
				</div>
			</section >
		);
	}
}

export default Homepage;
import { Route, Routes } from 'react-router-dom';
import About from './components/about/about.component';
import Contact from './components/contact/contact.component';
import Footer from './components/footer/footer.component';
import Homepage from './components/homepage/homepage.component';
import Projects from './components/projects/projects.component';
import Company from './components/legal/company.component';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={
					<>
						<Homepage />
						<Projects />
						<About />
						<Contact />
					</>
				} />
				<Route path="/company" element={<Company />} />
				{/* <Route path="/about" element={<About />} /> */}
			</Routes>
			<Footer />
		</>
	);
}

export default App;

import { useState } from 'react';
import mcx from './speakuni.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(mcx);
function Speakuni() {
	const [isShown, setIsShown] = useState(false);
	const classNames = cx({
		'row': true,
		'pr-viewing': isShown,
		'pr-speakuni': true
	});
	return (
		<div className={classNames} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
			<div className='box-5 box-md-8 box-center-md box-sm-9 box-center-sm box-off-1'>
				<div className='row'>
					<div className='row'>
						<div className='row'>
							<div className='row'>
								<div className={`box ${mcx['pr-screen-holder']}`}>
									<div className={`box ${mcx['pr-screen']}`}>
										<img className="scalable" src="/projects/speakuni/homepage.png"></img>
										<div className={mcx['pr-screen-display']}></div>
									</div>
									<div className={`${mcx['pr-screen-bezel']} ${mcx['side']}`}>
										<div className={mcx['prsb-corner']}></div>
									</div>
									<div className={`${mcx['pr-screen-bezel']} ${mcx['bottom']}`}>
										<div className={mcx['prsb-corner']}></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='box-3 box-md-8 box-center-md box-sm-9 box-center-sm box-off-1'>
				<div className='row'>
					<div className='box-8'>
						<div className={`row ${mcx['pr-introduction']}`}>
							<div className={`row ${mcx['pr-title-header']}`}>
								<div className={`box ${mcx['pr-title-holder']}`}>
									<div className={mcx['pr-title']}>
										<div className="box box-auto">
											<img className={mcx['pr-thumbnail']} src='/projects/speakuni/Speakuni.png'></img>
										</div>
										<div className='box box-auto'>Speakuni</div>
									</div>
								</div>
							</div>
							<div className={`row ${mcx['pr-description-holder']}`}>
								<div className={`row ${mcx['pr-description']}`}>
									<div className='row pr-slogan'>
										Learn, speak, teach. Anytime, anywhere.
									</div>
									<div className='row'>
										Speakuni is a free learn-a-language app that allows anyone to create a (small) course to teach or learn a new language.
										It is a community driven platform where users are encouraged to provide their knowledge on a language. 
										For the simplest reasons users may decide to create a course to teach others their language. 
										As well as to enroll in courses, provided by other community members!
									</div>
								</div>
							</div>
							<div className={`row center ${mcx['pr-navigate']}`}>
								<a className={`btn ${mcx['pr-navigator']}`} rel="noreferrer" href='https://www.speakuni.com' target='_blank'>Visit</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Speakuni;
class GlobalService {
	// LOCAL DEVELOPMENT (use https/443)
	// public BASE_SERVER_URL: string = 'http://localhost:5000/api/';
	// public BASE_API_URL: string = 'http://localhost:5000/api/';
	// public BASE_DOMAIN_URL: string = 'http://alohashaka.com';

	// SERVER DEPLOYMENT
	public BASE_SERVER_URL: string = '/api/';
	public BASE_API_URL: string = '/api/';
	// public BASE_DOMAIN_URL: string = 'https://alohashaka.com';
}

export default GlobalService;
import { HashLink as Link } from 'react-router-hash-link';

interface LinkData {
	label: string,
	url: string,
	external: boolean
}

function FooterLink({ label, url, external }: LinkData) {
	return (
		<div className="row">
			{external ? (
				<a href={url}>{label}</a>
			) : (
				<Link to={url}>{label}</Link>
			)}
		</div>
	);
}

export default FooterLink;
import FooterLinkCategory from './footer.link.category.component';

interface LinkCategories {
	label: string,
	links: { label: string, url: string, external: boolean }[]
}

function Footer() {
	const curDate = new Date().getFullYear();
	const links: LinkCategories[] = [
		{
			label: 'Pages', links: [
				{ label: 'Home', url: '/#as_homepage', external: false },
				{ label: 'About', url: '/#as_about', external: false },
				{ label: 'Projects', url: '/#as_projects', external: false },
				{ label: 'Contact', url: '/#as_contact', external: false },
			]
		},
		{
			label: 'Projects', links: [
				{ label: 'Speakuni', url: 'https://speakuni.com', external: true },
			]
		},
		{
			label: 'Legal', links: [
				{ label: 'Cookies', url: 'cookies', external: false },
				{ label: 'Disclaimer', url: 'disclaimer', external: false },
				{ label: 'Company', url: 'company', external: false }
			]
		}
	];
	return (
		<footer className="page as-footer half" id='as_footer'>
			<div className="row as-footer-links-content">
				<div className="box-8 box-sm-9 box-md-8 box-center">
					<div className="row arow">
						<div className="box-2 box-sm-10 center-sm">
							<div className="as-footer-logo">
								<div className="as-logo"></div>
								<div className="as-banner"></div>
							</div>
						</div>
						{links.map((category: LinkCategories) => (
							<FooterLinkCategory key={category.label} label={category.label} links={category.links}></FooterLinkCategory>
						))}
					</div>
					<div className="as-footer-copyright">
						{curDate} Copyright - Aloha Shaka
					</div>
				</div>
			</div>
		</footer >
	);
}

export default Footer;